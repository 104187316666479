import React from 'react';
import './Companye.scss';

function Companye() {
  return (
    <section className='companye'>
      <div className="container">
        <h1 className='companye__title'>О Компании</h1>
        <h4 className='companye__subtitle'>Нет звука приятнее, чем гитарный перебор</h4>
        <p className='companye__text'>
          Есть над чем задуматься: активно развивающиеся страны третьего мира являются только методом политического участия и преданы социально-демократической анафеме. Приятно, граждане, наблюдать, как стремящиеся вытеснить традиционное производство, нанотехнологии являются только методом политического участия и ограничены исключительно образом мышления!
        </p>
        <p className='companye__text'>
          В частности, разбавленное изрядной долей эмпатии, рациональное мышление, а также свежий взгляд на привычные вещи - безусловно открывает новые горизонты для поэтапного и последовательного развития общества.
        </p>
        <p className='companye__text'>
          Равным образом, дальнейшее развитие различных форм деятельности играет важную роль в формировании первоочередных требований. Прежде всего, социально-экономическое развитие напрямую зависит от поэтапного и последовательного развития общества.
        </p>
        <p className='companye__text'>
          С другой стороны, современная методология разработки не оставляет шанса для анализа существующих паттернов поведения. Следует отметить, что дальнейшее развитие различных форм деятельности способствует повышению качества стандартных подходов.
        </p>
      </div>
    </section>
  );
}

export default Companye;